import React, { useEffect } from 'react';
import Spinner from '../components/Spinner';

const AuthDeezer = () => {
    const appId = "389224";
    const redirectUri = "https://turn-up.app/authDeezer"

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const uidParam = urlParams.get('uid');
        const marketParam = urlParams.get('market');
        const isOnboardingParam = urlParams.get('isOnboarding');
        if (code) {
            getDeezerAccessToken(code);
        } else {
            setTimeout(() => redirectToDeezerAuth(uidParam, marketParam, isOnboardingParam), 1000);
        }
    }, []);

    const redirectToDeezerAuth = async (uid, market, isOnboarding) => {
        await setItemAsync(uid, market, isOnboarding)

        const deezerAuthUrl = `https://connect.deezer.com/oauth/auth.php?app_id=${appId}&redirect_uri=${encodeURIComponent(redirectUri)}&perms=basic_access,email,listening_history`;
        window.location.href = deezerAuthUrl
    };

    const setItemAsync = (uid, market, isOnboarding) => {
        return new Promise((resolve) => {
            localStorage.setItem('uid', uid);
            localStorage.setItem('market', market);
            localStorage.setItem('isOnboarding', isOnboarding);
            resolve();
        });
    }

    const getDeezerAccessToken = async (authCode) => {
        const storedUid = localStorage.getItem('uid');
        const storedMarket = localStorage.getItem('market');
        const storedIsOnboarding = localStorage.getItem('isOnboarding');
        await fetch(`https://us-central1-que-du-sale.cloudfunctions.net/getDeezerAccessToken?authCode=${encodeURIComponent(authCode)}&market=${storedMarket}&uid=${storedUid}&isOnboarding=${storedIsOnboarding}`, {
            method: 'POST',
        })
            .then(() => window.location.href = "com.dately://")
            .catch(() => alert("Wrong identifiers"))
    };

    return (
        <div>
            <Spinner />
        </div>
    );
};

export default AuthDeezer;
