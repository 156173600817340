import React, { useEffect, useState } from 'react';
import appleMusicLogo from '../assets/appleMusic.png'; // Assurez-vous que le chemin est correct

function loadScript(src) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = () => reject(new Error(`Script load error for ${src}`));
        document.head.appendChild(script);
    });
}

const AuthAppleMusic = () => {
    const [musicKit, setMusicKit] = useState(null);

    useEffect(() => {
        const initializeMusicKit = async () => {
            try {
                await loadScript("https://js-cdn.music.apple.com/musickit/v1/musickit.js");
                configureMusicKit();
            } catch (error) {
                console.error("Failed to load MusicKit:", error);
            }
        };

        const configureMusicKit = () => {
            if (window.MusicKit) {
                const developerToken = process.env.REACT_APP_APPLE_DEVELOPER_TOKEN;
                const config = {
                    developerToken,
                    app: {
                        name: 'Turn Up',
                        build: '10.0.27'
                    }
                };
                const mk = window.MusicKit.configure(config);
                setMusicKit(mk);
                return mk;
            } else {
                console.error("MusicKit is not available on window object");
            }
        };

        initializeMusicKit();
    }, []);

    const handleAuth = async () => {
        if (musicKit) {
            try {
                const token = await musicKit.authorize();
                if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage(token);
                } else {
                    console.error('ReactNativeWebView is not available on window object');
                }
            } catch (error) {
                console.error('Error during MusicKit authentication:', error);
            }
        } else {
            console.error('MusicKit instance is not initialized');
        }
    };

    return (
        <div style={styles.container}>
            <img src={appleMusicLogo} alt="Apple Music Logo" style={styles.logo} />
            <p style={styles.text}>Connect to Apple Music to retrieve your favorite music</p>
            <button onClick={handleAuth} style={styles.button}>Connect to Apple Music</button>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
        textAlign: 'center',
        padding: '20px'
    },
    logo: {
        width: '150px',
        marginBottom: '20px'
    },
    text: {
        fontSize: '18px',
        color: '#333',
        marginBottom: '20px'
    },
    button: {
        backgroundColor: '#007aff',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '16px',
        cursor: 'pointer'
    }
};

export default AuthAppleMusic;
